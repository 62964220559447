<template>
	<div class="texture-background-beige">
		<div class="header-image">
			<h1 class="head-image-text">CAREER OPPORTUNITIES</h1>
		</div>
		<div class="context-container">
			<div class="context-container-opportunity">
				<p class="context-desc">
					<b-row>
						<b-row
							v-for="description in opportunityDescriptions"
							:key="description.id"
							cols="12"
						>
							<p class="paragraph-section">{{ description }}</p>
						</b-row>

						<b-row cols="12">
							<p class="paragraph-section">
								{{ careerOpportunities.whyJoinUsSection }}
							</p>
						</b-row>

						<b-row cols="12">
							<p class="paragraph-section">
								<strong>{{
									careerOpportunities.applicationStatementSection
								}}</strong>
							</p>
						</b-row>
					</b-row>
				</p>
			</div>

			<h2 class="context-title">CURRENT OPPORTUNITIES</h2>
			<div class="line"></div>
			<div class="job-container">
				<div class="job-title">
					<div v-for="job in jobs" :key="job.id">
						<div class="opportunity-item">
							<h3
								class="job-title-text"
								@click="goToJobWebSite(job.jobUrlLink)"
							>
								{{ job.jobOpening }}
							</h3>
							<span class="divider" />
							<a
								class="sub-job-title-text"
								@click="goToJobWebSite(job.jobUrlLink)"
								>{{ job.jobLinkStmt }}</a
							>
						</div>
					</div>
				</div>
			</div>

			<h2 class="context-title">BENEFITS</h2>
			<div class="line"></div>
			<div class="context-desc">
				<div v-for="benefit in benefits" :key="benefit.id">
					<div class="context-desc">
						<strong>{{ benefit.benefitType }} :</strong>
						<div>{{ benefit.benefitInfo }}</div>
					</div>
				</div>
			</div>

			<h2 class="context-title">INTERNSHIPS</h2>
			<div id="internship" class="line"></div>
			<p class="context-desc">{{ internship }}</p>
			<!-- <div class="button-container">
				<button class="custom-button">Learn More</button>
			</div> -->

			<h2 class="context-title">DIVERSITY, EQUITY & INCLUSION</h2>
			<div class="line"></div>
			<p class="context-desc">{{ diversity }}</p>
			<!-- <div class="button-container">
				<button class="custom-button">Learn More</button>
			</div> -->
		</div>
	</div>
</template>

<script>
import { getCompanyOpportunities } from "../../../services/company";
export default {
	data() {
		return {
			careerOpportunities: null,
			opportunityDescriptions: [],
			internship: "",
			diversity: "",
			benefits: [],
			jobs: [],
		};
	},
	methods: {
		goToJobWebSite(jobUrlLink) {
			const imageUrlRoute = this.$router.resolve({ path: jobUrlLink });
			window.open(imageUrlRoute.href.slice(1), "_blank");
		},
	},
	mounted() {
		const {
			careerOpportunitiesInformation,
			currentOpenings,
			currentBenefits,
			intership,
			diversity,
		} = getCompanyOpportunities();
		const {
			descriptionSection,
			applicationStatementSection,
			whyJoinUsSection,
		} = careerOpportunitiesInformation;

		this.opportunityDescriptions = descriptionSection.map(
			({ paragragh }) => paragragh
		);
		this.careerOpportunities = {
			applicationStatementSection,
			whyJoinUsSection,
		};

		this.internship = intership;
		this.diversity = diversity;
		this.jobs = currentOpenings;
		this.benefits = currentBenefits;
	},
};
</script>

<style lang="scss" scoped>
@import "../../../views/FrontendPages/CorporateInfoPages/corporate_info.css";

.opportunity-item {
	cursor: pointer;
}
.texture-background {
	margin-bottom: -18px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1; /* Make sure the background stays behind other content */
}
.texture-background::before {
	content: "";
	position: fixed; /* Fixed position */
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1; /* Place it behind the content */

	background-image: url("../../../assets/images/background/corporateinfopage/texture_bw-inverted@2x.png") !important;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.header-image {
	background-image: url("../../../assets/images/background/corporateinfopage/AdobeStock_292278205@2x.png") !important;
}

.context-container-opportunity {
	display: flex;
	flex-direction: column;

	padding-top: 2vw;
	padding-bottom: 2vw;
	font-size: 2vh;
	text-align: left;
	margin-left: 2px;
}

@media (max-width: 768px) {
	.header-image {
		background-image: url("../../../assets/images/background/corporateinfopage/AdobeStock_292278205.png") !important;
	}

	.context-container-opportunity {
		padding: 5vw;
	}

	.context-desc {
		font-size: 2vh !important;
	}
}
</style>
