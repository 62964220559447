import companyInformation from "../StaticData/companyInformation.json";
import companyOpportunities from "../StaticData/companyOpportunities.json";

/**
 * Retrieveing the company information from Company Information JSON Document
 */
export const getCompanyInformation = () => {
    return companyInformation
};

/**
 * Retrieveing the company information from Company Opportunities JSON Document
 */
export const getCompanyOpportunities = () => {
    return companyOpportunities;
};
